import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { Button, TextField, Autocomplete, Grid, FormControlLabel } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import InfoIcon from '@mui/icons-material/Info';
import Moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog, DialogTitle, DialogContent, DialogActions, Switch } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailIcon from '@mui/icons-material/Email';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TableWrapper = styled.div`

.materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListGetAppIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                    .actionApproveIconPosition{
                        display: block;
                    }
                    .actionRejectIconPosition{
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
         .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListGetAppIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionApproveIconPosition{
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionRejectIconPosition{
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
        .approveActionIcon {
            font-size: 17px;
            font-weight : 1500px ;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 1fr 1fr 0fr 0fr 0fr 0fr 0fr ;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .textField {
        width : 300px
    }

    .filterAutocomplete{
    
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 35px;
        padding-top : 0px;
    }
    .MuiFilledInput-input {
        padding: none;
        font-size: 12px;
        height : 10px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }}
    .filterDropdown {
        .dialogStyle {
            height : 200px ;
            width : 1000px ;
        }
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
           
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
        
    }
   
`;




const headCells = [
  { id: 'company', numeric: false, label: 'Company' },
  { id: 'client', numeric: false, label: 'Site' },
  { id: 'jobLocation', numeric: false, label: 'Job Location' },
  { id: 'submittedBy', numeric: false, label: 'Employee' },
  { id: 'formId', numeric: false, label: 'Form Name' },
  { id: 'inspectionDate', numeric: false, label: 'Inspection Date' },
  { id: 'approvalStatus', numeric: false, label: 'Approval' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function FormSubmission() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [rows, setRows] = React.useState([]);
  var [filteredRows, setFilteredRows] = React.useState([]);
  var [startDate, setStartDate] = React.useState("");
  var [endDate, setEndDate] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [pdfMessage, setPdfMessage] = React.useState("")
  const [rejectedMessage, setRejectedMessage] = React.useState("");
  var [employeeId, setEmployeeId] = React.useState("");
  var [clientId, setClientId] = React.useState(-1);
  var [employeeDetails, setEmployeeDetails] = React.useState([]);
  var [clientDetails, setClientDetails] = React.useState([]);
  var [url, setUrl] = useState();
  const [email, setEmail] = useState("");
  var [open, setOpen] = React.useState(false);
  var [openPdf, setOpenPdf] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [disableClient, setDisableClient] = React.useState(true);
  var [startDateRange, setStartDateRange] = React.useState("");
  var [endDateRange, setEndDateRange] = React.useState("");
  const [pfreferenceId, setPfreferenceId] = React.useState();
  const [formId, setFormId] = React.useState("0");
  const [notes, setNotes] = React.useState("");
  var [userProfile, setUserProfile] = React.useState();
  var [userEmail, setUserEmail] = React.useState("");
  var [approvePopup, setApprovePopup] = React.useState(false);
  const [includeMyReports, setIncludeMyReports] = React.useState(false);
  var [pfId, setPfId] = React.useState();
  var [pfIdArray, setPfIdArray] = React.useState([]);
  var [selectAll, setSelectAll] = React.useState(false);
  var [userType, setUserType] = React.useState("");
  const notifyApproveWithoutSelected = () => toast("Select atleast one row!");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    filteredRows = stabilizedThis.map((el) => el[0])
    return stabilizedThis.map((el) => el[0]);
  }


  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {userType != "User" && headCell.label == 'Company' && <Checkbox
                checked={selectAll}
                style={{ color: "#F59300", paddingLeft: "13px" }}
                onChange={(event) => selectAllRows(event)}
              />}
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >

                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const formData = [{ formId: "147117107", formName: "OSHA Compliance" },
  { formId: "146928038", formName: "Industrial Compliance" }, { formId: "1422306014", formName: "Training" }, { formId: "1422306010", formName: "Workorder" }]

  useEffect(() => {

    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile?.userEmail;
    setUserEmail(userEmail);
    userType = userProfile?.userType;
    setUserType(userType);


    const MOBILESURETY_URL = config.API_URL + "/api/employee/basic/" + userEmail
    const token = config.TOKEN
    axios.get(MOBILESURETY_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          employeeDetails = res.data.data
          setEmployeeDetails(employeeDetails)
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }

      })

    const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/site/basic/" + userEmail
    axios.get(MOBILESURETY_CLIENT_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          clientDetails = res.data.data
          setClientDetails(clientDetails)
          setDisableClient(false);
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }
      })
    const MOBILESURETY_DATE_URL = config.API_URL + "/api/daterange"
    axios.get(MOBILESURETY_DATE_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          startDateRange = res.data.data[0]
          setStartDateRange(startDateRange)
          endDateRange = res.data.data[1]
          setEndDateRange(endDateRange)
          var firstDateArray = res.data.data[0].split("T")
          startDate = firstDateArray[0]
          setStartDate(startDate)
          var lastDateArray = res.data.data[1].split("T")
          endDate = lastDateArray[0]
          setEndDate(endDate)
          //list timeentry with daterange
          var postData = {
            "startDate": startDateRange,
            "endDate": endDateRange,
            "employeeEmail": employeeId,
            "customerId": clientId,
            "formId": formId,
            "userEmail": userEmail,
            "includeMyReports": includeMyReports
          }
          const MOBILESURETY_TIMEENTRY_URL = config.API_URL + "/api/inspection/search"

          axios.post(MOBILESURETY_TIMEENTRY_URL, postData, {
            headers: {
              'Authorization': `Basic ${token}`
            }
          })
            .then((res) => {
              if (res.data.successCode === 0) {
                rows = res.data.data
                setRows(rows);
                filteredRows = rows;
                setFilteredRows(rows);
              } else {
                setPdfMessage(
                  <div className="message">
                    <div className="alert alert-danger" role="alert">
                      "An error occured,try again later"
                    </div>
                  </div>)
                setTimeout(() => { setPdfMessage("") }, 5000)
              }
            })
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }
      })
  }, [])

  const searchInspection = () => {
    var postData = {
      "startDate": startDate + "T00:00:00",
      "endDate": endDate + "T23:59:00",
      "employeeEmail": employeeId,
      "customerId": clientId,
      "formId": formId,
      "userEmail": userEmail,
      "includeMyReports": includeMyReports
    }
    const MOBILESURETY_URL = config.API_URL + "/api/inspection/search"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          rows = res.data.data
          setRows(rows);
          filteredRows = rows;
          setFilteredRows(rows);
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }
      })
  }


  const handleSearch = () => {
    searchInspection()

  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPdf = (pfreferenceId) => {
    const MOBILESURETY_DOWNLOAD_URL = config.API_URL + "/api/formsearch/file/" + pfreferenceId
    const token = config.TOKEN
    axios.get(MOBILESURETY_DOWNLOAD_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        var respData = res.data;

        if (respData.successCode === 0) {
          if (respData.data.fileData != null && respData.data.fileName != null) {
            const url = 'data: application/pdf;base64,' + respData.data.fileData
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', respData.data.fileName);
            document.body.appendChild(link);
            link.click();
          }
          else {
            setPdfMessage(
              <div className="message">
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  "No Pdf File Found!"
                </div>
              </div>)
            setTimeout(() => { setPdfMessage("") }, 5000)
          }
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                "An error occured, try again later!"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }

      }).catch(err => {
        console.log(err)
      })
  }


  const handlePdfOpen = (pfid) => {

    const MOBILESURETY_DOWNLOAD_URL = config.API_URL + "/api/formsearch/file/" + pfid

    const token = config.TOKEN
    axios.get(MOBILESURETY_DOWNLOAD_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        var respData = res.data;
        if (respData.successCode === 0) {

          if (respData.data.fileData != null && respData.data.fileName != null) {
            url = 'data: application/pdf;base64,' + respData.data.fileData;
            setUrl(url)
            setOpenPdf(true);
          } else {
            setPdfMessage(
              <div className="message">
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  "No Pdf File Found!"
                </div>
              </div>)
            setTimeout(() => { setPdfMessage("") }, 5000)
          }
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                "An error occured, try again later!"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }

      }).catch(err => {
        console.log(err)

      })
  }

  const handleClickOpen = (pfId) => {
    setPfreferenceId(pfId)
    setOpen(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const sendEmail = () => {
    setDisable(true)
    const MOBILESURETY_URL = config.API_URL + "/api/inspection/sendemail"
    const token = config.TOKEN
    var postData = {
      "pfReferenceId": pfreferenceId,
      "emailList": email
    }
    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        {
          if (res.data.successCode === 0) {
            setMessage(
              <div className="message">
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  Email sent successfully.
                </div>
              </div>)
          } else {
            setMessage(
              <div className="message">
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  "An error occured, contact support".
                </div>
              </div>)
          }
          setTimeout(() => { setMessage("") }, 5000)
          setDisable(false)
        }
      })
  }


  const icons = (filteredRows) => {
    return (
      <div>
        <div className="actionListGetAppIconPosition">
          <Tooltip title="Download" aria-label="Download">
            <IconButton className="actionBtn" aria-label="Download" onClick={() => { downloadPdf(filteredRows.pfReferenceId) }}>
              <GetAppIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="actionListVisibilityIconPosition">
          <Tooltip title="View" aria-label="View">
            <IconButton className="actionBtn" aria-label="View" onClick={() => handlePdfOpen(filteredRows.pfReferenceId)}>
              <VisibilityIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
          <Dialog open={openPdf} closeonescape="true" height="600px" fullWidth={true} maxWidth="lg" >
            <TableWrapper>
              <DialogTitle>
                <div className="text-right">
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClosePdf} />
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="text-right">
                  <div style={{
                    border: '1px solid rgba(0,0,0,0.3)',
                    height: '750px',
                    width: '100%'
                  }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                      <Viewer width="100%"
                        fileUrl={url} />
                    </Worker>
                  </div>
                </div>
              </DialogContent>
            </TableWrapper>
          </Dialog>
        </div>
        <div className="actionListEmailIconPosition">
          <Tooltip title="Email" aria-label="Email">
            <IconButton className="actionBtn" aria-label="Email" onClick={() => handleClickOpen(filteredRows.pfReferenceId)}>
              <EmailIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
          <Dialog open={open} closeonescape="true" fullWidth={true} maxWidth="sm" >
            <TableWrapper>
              <DialogTitle>
                <div className="text-right">
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClose} />
                </div>
                Email
              </DialogTitle>
              <DialogContent className="dialogStyle">
                <div className={`${classes.root} materialTableStyle`}>
                  <div className="filterRowUpdate">
                    <TextField fullWidth label="Email Id" variant="standard" onChange={(event) => setEmail(event.target.value)} />

                  </div>
                </div>
                <DialogActions>
                  <Grid container spacing={4} className="mt-3">
                    <Grid item xs={12} sm={12} md={12} className="text-right">
                      <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClose}>
                        Cancel
                      </Button>&nbsp;&nbsp;
                      <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => sendEmail()}>
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
                {message}
              </DialogContent>
            </TableWrapper>
          </Dialog>
        </div>
      </div >
    )
  }

  const downloadcsv = () => {

    var postData = {
      "startDate": startDate + "T00:00:00",
      "endDate": endDate + "T23:59:00",
      "employeeEmail": employeeId,
      "customerId": clientId,
      "formId": formId
    }
    const MOBILESURETY_URL = config.API_URL + "/api/inspection/export"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          const data = res.data.data;
          const element = document.createElement("a");
          const file = new Blob([data], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = 'export.csv';
          document.body.appendChild(element);
          element.click();
        } else {
          setPdfMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setPdfMessage("") }, 5000)
        }
      })
  }

  const openRejectPopup = (filteredRows) => {
    pfId = filteredRows.pfReferenceId;
    setPfId(pfId);
    approvePopup = true;
    setApprovePopup(approvePopup);
  }

  const closeRejectPopup = () => {
    approvePopup = false;
    setApprovePopup(approvePopup);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    isSelectedCheckboxes(newPage)
  };

  const isSelectedCheckboxes = (page) => {
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    const selectedRows = filteredRows.slice(trimStart, trimEnd)
    if (selectedRows.every(x => x.select === true)) {
      selectAll = true
    } else {
      selectAll = false
    }
    setSelectAll(selectAll)
  }


  const selectAllRows = (event) => {

    setSelectAll(event.target.checked)
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    for (let index = trimStart; index < trimEnd; index++) {
      let element = filteredRows[index];
      element['select'] = event.target.checked
    }
  }

  const onChangeCheckedBox = (checked, index) => {
    console.log(filteredRows[index]);
    let pageIndex = index + (page * rowsPerPage)
    filteredRows[pageIndex]['select'] = checked
    filteredRows = [...filteredRows]
    setFilteredRows(filteredRows)
    console.log(checked)
  }


  const approveForm = (filteredRows, string) => {
    pfIdArray.push(filteredRows.pfReferenceId)
    setPfIdArray(pfIdArray);
    var postData = {
      "approvalStatus": string,
      "approvalBy": userEmail,
      "comment": notes,
      "pfReferenceId": pfIdArray
    }
    approveReject(postData, string)
  }

  const rejectForm = (string) => {
    pfIdArray.push(pfId)
    setPfIdArray(pfIdArray);
    var postData = {
      "approvalStatus": string,
      "approvalBy": userEmail,
      "comment": notes,
      "pfReferenceId": pfIdArray
    }
    approveReject(postData, string)
  }

  const approveSelected = () => {
    pfIdArray = filteredRows.filter(x => x.select === true).map(f => f.pfReferenceId)
    setPfIdArray(pfIdArray);
    if (pfIdArray.length > 0) {
      var postData = {
        "approvalStatus": "Approved",
        "approvalBy": userEmail,
        "comment": notes,
        "pfReferenceId": pfIdArray
      }
      setSelectAll(false)
      for (let index = 0; index < filteredRows.length; index++) {
        let element = filteredRows[index];
        element['select'] = false
      }
      approveReject(postData, "Approved")
    }
    else {
      notifyApproveWithoutSelected()
    }
  }

  const approveReject = (postData, string) => {
    pfIdArray = [];
    setPfIdArray(pfIdArray);

    const MOBILESURETY_URL = config.API_URL + "/api/inspection/approval"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          if (string == "Approved") {
            setMessage(
              <div className="message">
                <div className="alert alert-success" role="alert">
                  Form Approved Successfully!
                </div>
              </div>)
            setTimeout(() => { setMessage("") }, 5000)
          }
          else {
            setRejectedMessage(
              <div className="message">
                <div className="alert alert-success" role="alert">
                  Form Rejected Successfully!
                </div>
              </div>)
            setTimeout(() => { setRejectedMessage("") }, 5000)
            setTimeout(() => {
              approvePopup = false;
              setApprovePopup(approvePopup);
            }, 5000)
          }
          searchInspection()
        } else {
          if (string == "Approved") {
            setMessage(
              <div className="message">
                <div className="alert alert-danger" role="alert">
                  "An error occured,try again later"
                </div>
              </div>)
            setTimeout(() => { setMessage("") }, 5000)
          } else {
            setRejectedMessage(
              <div className="message">
                <div className="alert alert-danger" role="alert">
                  "An error occured,try again later"
                </div>
              </div>)
            setTimeout(() => { setRejectedMessage("") }, 4000)
            setTimeout(() => {
              approvePopup = false;
              setApprovePopup(approvePopup)
            }, 2000)
          }
        }
      })
  }

  const approve = (filteredRows) => {
    if (userType == "Admin") {
      if (filteredRows.showAction && filteredRows.approvalStatus == "Pending") {
        return (
          <div>
            <div className="actionApproveIconPosition">
              <Tooltip title="Approve" aria-label="Approve">
                <IconButton className="actionBtn" aria-label="Approve" onClick={() => approveForm(filteredRows, "Approved")}>
                  <CheckIcon className="approveActionIcon" />
                </IconButton>
              </Tooltip>
            </div>
            {rejectIcon(filteredRows)}
            {filteredRows.approvalStatus}
          </div>
        )
      } else if (filteredRows.approvalStatus == "Approved") {
        return (
          <div>
            {rejectIcon(filteredRows)}
            {filteredRows.approvalStatus}
          </div>
        )
      }
      else {
        return (
          filteredRows.approvalStatus
        )
      }
    }
    else {
      return (
        filteredRows.approvalStatus
      )
    }
  }

  const rejectIcon = (filteredRows) => {
    return (
      <div className="actionRejectIconPosition">
        <Tooltip title="Reject" aria-label="Reject">
          <IconButton className="actionBtn" aria-label="Reject" onClick={() => openRejectPopup(filteredRows)}>
            <ClearIcon className="actionIcon" />
          </IconButton>
        </Tooltip>
        <Dialog open={approvePopup} closeonescape="true" fullWidth={true} maxWidth="sm" >
          <TableWrapper>
            <DialogTitle>
              <div className="text-right">
                <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={closeRejectPopup} />
              </div>
              Reject
            </DialogTitle>
            <DialogContent className="dialogStyle">
              <div className={`${classes.root} materialTableStyle`}>
                <div className="filterRowUpdate">
                  <TextField fullWidth multiline label="Notes" variant="standard" onChange={(event) => setNotes(event.target.value)} />
                </div>
              </div>
              <DialogActions>
                <Grid container spacing={4} className="mt-3">
                  <Grid item xs={12} sm={12} md={12} className="text-center">
                    <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => rejectForm("Rejected")}>
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
              {rejectedMessage}
            </DialogContent>
          </TableWrapper>
        </Dialog>
      </div>
    )
  }


  return (
    <div>
      <ToastContainer theme="dark" />
      <TableWrapper>
        {message}
        {pdfMessage}
        <div className={`${classes.root} materialTableStyle`}>

          <div className="filterRow">

            <Autocomplete
              options={clientDetails}
              className="filterAutocomplete"
              getOptionLabel={(option) => option.name}
              onChange={(event, client) => {
                client != null ? setClientId(client.id) : setClientId(-1)
              }}
              renderInput={(params) => <TextField  {...params} fullWidth label="Select Client" className="filter" variant="filled" />
              }
            />
            {userType != "ClientUser" ? <Autocomplete
              options={employeeDetails}
              className="filterAutocomplete"
              getOptionLabel={(option) => option.name}
              onChange={(event, employee) => {
                employee != null ? setEmployeeId(employee.email) : setEmployeeId("")
              }}
              renderInput={(params) => <TextField  {...params} fullWidth label="Select Employee" variant="filled" />
              }
            /> : ""}
            {/* <Autocomplete
              options={formData}
              className="filterAutocomplete"
              getOptionLabel={(option) => option.formName}
              onChange={(event, option) => {
                option != null ? setFormId(option.formId) : setFormId("0")
              }}
              renderInput={(params) => <TextField
                {...params} fullWidth label="Select Form" className="filterAutocomplete" variant="filled" />
              }
            /> */}
            <div>
              <FilterInput style={{ height: "35px" }} placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
            </div>
            <div>
              <FilterInput style={{ height: "35px" }} placeholder="End Date(yyyy-mm-dd)" type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
            </div>
            {userType != "ClientUser" ? <div>
              <Tooltip title="Include My Reports">
                <Switch
                  checked={includeMyReports}
                  name="status"
                  color="warning"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onClick={(event) => setIncludeMyReports(event.target.checked)}
                />
              </Tooltip>
            </div> : ""}
            <span>
              <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={handleSearch} >
                Search
              </Button>
            </span>
            {userType == "Admin" && <span>
              <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={approveSelected} >
                Approve
              </Button>
            </span>}
            <span>
              <div>
                <Tooltip title="Download CSV" aria-label="Download">
                  <IconButton className="actionCSVBtn csvDownloadIcon" aria-label="Download" onClick={() => { downloadcsv() }}>
                    <GetAppIcon className="actionIcon" />
                  </IconButton>
                </Tooltip>
              </div>
            </span>
          </div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((filteredRows, index) => {
                    filteredRows.inspectionDate = Moment(Date.parse(filteredRows.inspectionDate)).format('MM/DD/YYYY')
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={filteredRows.id}
                      >
                        <TableCell>
                        <div className='d-flex'>{userType != "User" && <Checkbox
                            checked={filteredRows.select === true}
                            style={{ color: "#F59300" }}
                            onChange={(event) => onChangeCheckedBox(event.target.checked, index)}
                          />}
                            <span className='mt-2'>{filteredRows.company}</span>
                          </div>
                          
                        </TableCell>
                        <TableCell>
                          {filteredRows.client}
                        </TableCell>
                        
                        <TableCell>
                          {filteredRows.jobLocation}
                        </TableCell>
                        <TableCell>
                          {filteredRows.submittedBy}
                        </TableCell>
                        <TableCell>
                          {filteredRows.formName}
                        </TableCell>
                        <TableCell>
                          {filteredRows.inspectionDate}
                          {icons(filteredRows)}
                        </TableCell>
                        <TableCell>
                          {approve(filteredRows)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="paginationLabel"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </TableWrapper>
    </div>

  );
}



