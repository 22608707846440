import React, { useEffect, useState } from 'react';
import Moment from 'moment'
import config from '../config.json';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, CardContent, Button } from '@mui/material';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { faChartBar, faLightbulb } from '@fortawesome/free-solid-svg-icons';


const DashboardWrapper = styled.div`
  .dashboardGradientCard {
    height: 110px;
  }

  .font07 {
    font-size: 0.7rem;
  }  

  .dashboardCardHeight {
    height: 100% !important;
  }
`;

export default function Dashboard() {

  var [dashboardCount, setDashboardCount] = React.useState({
    inspection: 0,
    training: 0,
    workOrder: 0,
    totalCount: 0,
    inspectionTotal: 0,
    trainingTotal: 0,
    workOrderTotal: 0
  });
  var [totalCount, setTotalCount] = React.useState(0);
  var [batchRuns, setBatchRuns] = useState([]);
  var [lastSubmits, setLastSubmits] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  var [userEmail, setUserEmail] = React.useState("");
  var [userProfile, setUserProfile] = React.useState();

  const options = {
    chart: {
      type: 'pie',
    },
    labels: ['Inspection', 'Training', 'Work Order'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };
  const ydtoptions = {
    chart: {
      type: 'pie',
    },
    labels: ['Inspection Total', 'Training Total', 'Work Order Total'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };
  const series = [dashboardCount.inspection, dashboardCount.training, dashboardCount.workOrder]
  const ydtseries = [dashboardCount.inspectionTotal, dashboardCount.trainingTotal, dashboardCount.workOrderTotal]


  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail)
    const MOBILESURETY_URL = config.API_URL + "/api/dashboard/count/" + userEmail
    const token = config.TOKEN
    axios.get(MOBILESURETY_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          dashboardCount = res.data.data
          setDashboardCount(dashboardCount);
          if (dashboardCount !== undefined) {
            totalCount = (dashboardCount.pfCount + dashboardCount.uiCount + dashboardCount.exceptionCount);
            setTotalCount(totalCount)
          }
        } else {
          setMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setMessage("") }, 5000)
        }
      })

    const MOBILESURETY_Batch_URL = config.API_URL + "/api/dashboard/supervisorView"

    axios.get(MOBILESURETY_Batch_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          setStartDate(Moment(Date.parse(res.data.data.startDate)).format('YYYY-MM-DD'))
          setEndDate(Moment(Date.parse(res.data.data.endDate)).format('YYYY-MM-DD'))
          batchRuns = res.data.data.viewDataList
          setBatchRuns(batchRuns)
        } else {
          setMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setMessage("") }, 5000)
        }
      })
    const MOBILESURETY_Submit_URL = config.API_URL + "/api/dashboard/lastsubmits/" + userEmail

    axios.get(MOBILESURETY_Submit_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          lastSubmits = res.data.data
          setLastSubmits(lastSubmits)
        } else {
          setMessage(
            <div className="message">
              <div className="alert alert-danger" role="alert">
                "An error occured,try again later"
              </div>
            </div>)
          setTimeout(() => { setMessage("") }, 5000)
        }
      })
  }, []);


  const BatchData = {
    // header: ['Date', 'Approved', 'Quickbooks']
    header: ['Supervisor Name', 'Approved', 'Pending Approval']
  }

  const LastSubmitData = {
    header: ['Client', 'Employee', 'Form Name', 'Inspection Date', 'Payroll Item', 'Service Item']
  }


  const badge = (DueInDays) => {
    if (DueInDays < 0) {

      return (
        <div className="btn-pill m-1 badge badge-danger font07">{DueInDays} days</div>
      )
    }

    if (DueInDays >= 0) {

      return (
        <div className="btn-pill m-1 badge badge-success font07">{DueInDays} days</div>
      )
    }

  }

  const checkApproval = (batchRuns) => {
    if (batchRuns.approved === true) {
      return (<CheckIcon></CheckIcon>)
    }
    if (batchRuns.approved === false) {
      return (<ClearIcon></ClearIcon>)
    }
  }

  const checkQb = (batchRuns) => {
    if (batchRuns.published === true) {
      return (<CheckIcon></CheckIcon>)
    }
    if (batchRuns.published === false) {
      return (<ClearIcon></ClearIcon>)
    }
  }

  return (
    <DashboardWrapper>
      {message}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box bg-premium-dark dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Inspection Count
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.inspection}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-asteroid dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Training Count
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.training}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-midnight-bloom dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Work order Count
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.workOrder}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-night-sky dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Total
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.totalCount}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Pay Period Details</div>
            </div>
            <CardContent className="p-3">
              <Chart options={options} type="pie" series={series} width="320" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">YTD Details</div>
            </div>
            <CardContent className="p-3">
              <Chart options={ydtoptions} type="pie" series={ydtseries} width="320" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

     <Grid container spacing={4}>
     {userProfile?.userType != "ClientUser" ? <Grid item xs={12} sm={12} md={12}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Approval Details
                <span className='px-2' style={{ fontSize: "12px" }}>{startDate && ("(" + startDate + " to " + endDate + ")")}</span>
              </div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>
                      {BatchData.header.map((headerItem, index) => (
                        <th className="text-left" key={index}>{headerItem}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {batchRuns.map((batchRuns, index) => (
                      <tr key={index}>
                        <td className="text-left">
                          <div className="text-black font07">{batchRuns.supervisorName}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{batchRuns.approved}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{batchRuns.pendingApproval}</div>
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
            {/* <div className="card-footer d-flex justify-content-between text-right">
              <div className="w-100">
                <Button
                  size="small"
                  variant="outlined"
                  className="mr-3 cancelBtn"
                  color="primary">
                  View all
                </Button>
              </div>
            </div> */}
          </Card>
        </Grid> : " "}
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="card-box noShadow mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Form Submission Details</div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>
                      {LastSubmitData.header.map((headerItem, index) => (
                        <th className="text-left" key={index}>{headerItem}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {lastSubmits.slice(0, 5).map((lastSubmits, index) =>

                    (
                      <tr key={lastSubmits.id}>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.client}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.submittedBy}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.formName}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.inspectionDate = Moment(Date.parse(lastSubmits.inspectionDate)).format('MM/DD/YYYY')}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.payrollItem}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{lastSubmits.serviceItem}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
            <div className="card-footer d-flex justify-content-between text-right">
              <div className="w-100">
                <Button
                  size="small"
                  variant="outlined"
                  className="mr-3 cancelBtn"
                  color="primary"
                  onClick={() => navigate('/formsubmission/list')}>
                  View all
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>

    </DashboardWrapper>
  );
}


