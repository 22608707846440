import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Button, MenuItem, TextField, FormControl, FormControlLabel, Grid, Input, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
`;

export default function AddUser() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    const token = config.TOKEN;
    const { id } = useParams();
    var [portalLogin, setPortalLogin] = React.useState(false);
    const navigate = useNavigate();
    var [supervisorList, setSupervisorList] = React.useState([]);
    var [rows, setRows] = React.useState([
        {
            "name": "",
            "qbName": "",
            "email": " ",
            "isActive": false,
            "customerId" : null,
            "regionId" : null
        }
    ]);
    var [userType, setUserType] = React.useState("");
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    var [clientList, setClientList] = React.useState([]);
    var [company, setCompany] = React.useState("");
    var [regionList, setRegionList] = React.useState([]);

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userType = userProfile.userType;
        setUserType(userType)
        userEmail = userProfile.userEmail
        setUserEmail(userEmail)

        if (id) {
            const MOBILESURETY_USER_URL = config.API_URL + "/api/employee/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data[0]
                        setRows(rows);
                        portalLogin = rows.portalLogin
                        setPortalLogin(portalLogin);
                        if (rows.customerId != null) {
                            company = rows?.customerId
                            setCompany(company)
                            getRegion(company)
                        }

                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }

        const MOBILESURETY_USER_URL = config.API_URL + "/api/employeelist"
        axios.get(MOBILESURETY_USER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    let employees = res.data.data
                    supervisorList = employees.filter(e => e.isSupervisor == true).map(e => ({ "name": e.name, "id": e.id }))
                    setSupervisorList(supervisorList)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })

        getCompany()
        getRegion()

    }, [])


    const handlePortalLogin = (event) => {
        portalLogin = event.target.checked
        setPortalLogin(portalLogin)
    }

    const UserTypeList = ["Admin", "Manager", "User"]

    const displayUserType = (values, handleChange, handleBlur, errors, touched) => {
        if (portalLogin) {
            return (
                <React.Fragment >
                    <Grid item xs={12} sm={8} md={8} className="pt-0">
                        <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                            <TextField
                                // fullWidth
                                style={{ width: "99%" }}
                                className="filterDropdown"
                                id="status"
                                select
                                label="Select User Type"
                                helperText=""
                                variant="standard"
                                name="UserType"
                                value={values.UserType || ''}
                                onBlur={handleBlur}
                                onChange={handleChange}>
                                {UserTypeList.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                        <TextField label="Password" variant="standard" value={values.Password || ''} onBlur={handleBlur} name="Password" onChange={handleChange} />
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const getCompany = () => {
        const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/clientlistbasic/" + userEmail
        axios.get(MOBILESURETY_CLIENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    clientList = res.data.data
                    setClientList(clientList);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const getRegion = (companyId) => {
        const MOBILESURETY_REGION_URL = config.API_URL + "/api/regionlist/" + userEmail
        axios.get(MOBILESURETY_REGION_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    regionList = res.data.data
                    setRegionList(regionList)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const handleCompanyandRegion = (values, handleChange, handleBlur, errors, touched) => {
        if (userType == "Admin" && portalLogin) {
            return (
                <React.Fragment >
                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                        <TextField
                            // fullWidth
                            style={{ width: "99%" }}
                            className="filterDropdown"
                            id="status"
                            select
                            label="Select Company"
                            helperText=""
                            variant="standard"
                            name="CompanyId"
                            value={values.CompanyId || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}>
                            {/* onChange={(e) => customHandleChange(e)}> */}
                            {clientList.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span className="errMsg">{errors.CompanyId && touched.CompanyId && errors.CompanyId}</span>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                        <TextField
                            // fullWidth
                            style={{ width: "99%" }}
                            className="filterDropdown"
                            id="status"
                            select
                            label="Select Region"
                            helperText=""
                            variant="standard"
                            name="Region"
                            value={values.Region || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}>
                            {regionList.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const customHandleChange = (e) => {

        company = e.target.value
        setCompany(company)
        getRegion(company)
    };



    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                Name: rows?.name, QbName: rows?.qbName, Email: rows?.email, IsActive: false,
                UserType: rows?.userType ? rows?.userType : null, Password: null,
                Supervisor: rows?.supervisorId, CompanyId: rows.customerId, Region: rows?.regionId
            }}
            validate={values => {
                const errors = {};
                if (!values.Name) {
                    errors.Name = 'Required!';
                }
                if (!values.QbName) {
                    errors.QbName = 'Required!';
                }

                if (!values.Email) {
                    errors.Email = 'Required!';
                }

                /*
                if (!values.CompanyId) {
                    errors.CompanyId = 'Required!';
                }
                if (!values.Region) {
                    errors.Region = 'Required!';
                }*/

                return errors;
            }}

            const handleMouseDownPwd={(event) => {
                event.preventDefault();
            }}
            onSubmit={(values, { setSubmitting }) => {
                const postData = {
                    id: id ? id : 0,
                    name: values.Name,
                    qbName: values.QbName,
                    email: values.Email,
                    isActive: values.IsActive,
                    portalLogin: portalLogin,
                    userType: values.UserType,
                    password: values.Password,
                    supervisorId: values.Supervisor,
                    customerId : values.CompanyId,
                    regionId: values.Region
                    
                }
                // same api for add & edit
                const MOBILESURETY_URL = config.API_URL + "/api/employee/add"
                axios.post(MOBILESURETY_URL, postData, {
                    headers: {
                        'Authorization': `Basic ${token}`
                    }
                })
                    .then((res) => {

                        if (res.data.successCode === 0) {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-success" role="alert">
                                        {id ? "Employee Info Updated Successfully!" : "User Added successfully!"}
                                    </div>
                                </div>)
                        } else {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-danger" role="alert">
                                        "An error occured,try again later"
                                    </div>
                                </div>)
                        }
                        navigate("/user/list")
                    })
                setSubmitting(false)
            }}
        >

            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <UserWrapper>
                        {message}
                        <Grid container spacing={4} className='d-flex justify-content-center'>
                            <Grid item xs={12} sm={8} md={8} className="pt-4">
                                <PageTitle>Employee</PageTitle>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="Name" variant="standard" value={values.Name || ''} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="QuickBooks Name" variant="standard" value={values.QbName || ''} onBlur={handleBlur} name="QbName" onChange={handleChange} />
                                <span className="errMsg">{errors.QbName && touched.QbName && errors.QbName}</span>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} name="Email" onChange={handleChange} />
                                <span className="errMsg">{errors.Email && touched.Email && errors.Email}</span>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField
                                    // fullWidth
                                    style={{ width: "99%" }}
                                    className="filterDropdown"
                                    id="status"
                                    select
                                    label="Select Supervisor"
                                    helperText=""
                                    variant="standard"
                                    name="Supervisor"
                                    value={values.Supervisor || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}>
                                    {supervisorList.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="py-4 pb-0">
                                <FormControlLabel control={<Checkbox
                                    checked={portalLogin}
                                    style={{ color: "#F59300" }}
                                    onChange={(event) => handlePortalLogin(event)}
                                />}
                                    label="Portal Login" />
                            </Grid>
                            {displayUserType(values, handleChange, handleBlur, errors, touched)}
                            {handleCompanyandRegion(values, handleChange, handleBlur, errors, touched)}

                            <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/list")}>
                                    Cancel
                                </Button>&nbsp;&nbsp;
                                <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </UserWrapper>
                </form>
            )}
        </Formik>
    );
}
