import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const FilterWapper = styled.div`
    position: relative;

    .styledInput {
        width: 100%;
        background-color: ${props => props.bgColor};
        color: ${props => props.color};
        font-size: ${props => props.fontSize};
        border: none;
        padding: ${props => props.icon ? '5px 24px 5px 5px' : '5px 5px 5px 5px'}

    }
    .rightIcon {
        position: absolute;
        top: 3px;
        right: 3px;
    }
`;

export function FilterInput({bgColor, color, type, placeholder, classes, fontSize, icon, ...props}) {
    
    return (
        <FilterWapper bgColor={bgColor} color={color} fontSize={fontSize} icon={icon}>
            <input type={type} className={`styledInput ${classes}`} placeholder={placeholder} {...props} />
            {icon && (<div className="rightIcon">{icon}</div>)}
        </FilterWapper>
    )
}

FilterInput.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    classes: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
   // icon: PropTypes.array,
    fontSize: PropTypes.string,
};

FilterInput.defaultProps = {
    bgColor: '#ffffff',
    color: '#000000',
    type: 'text',
    classes: '',
    placeholder: '',
    fontSize: '13px',
    icon: ''
};
