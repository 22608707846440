import React from 'react';
import { matchPath } from 'react-router-dom';

import PropTypes from 'prop-types';

import { List, Typography } from '@mui/material';

import useRouter from '../../utils/useRouter'
import SidebarMenuListItem from './SidebarMenuListItem';
import Qblogo from '../../assets/images/quicbooks/qblogo.png';
import config from '../../pages/config.json';
import axios from 'axios';

const SidebarMenuList = props => {
  const { pages, ...rest } = props;

  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = props => {
  const { router, items, page, depth } = props;

  let userType = localStorage.getItem("userType");

  if (page.content) {
    const open = matchPath(router.location.pathname, {
      path: page.to,
      exact: false
    });
    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}>
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            pages={page.content}
            router={router}
          />
        </div>
      </SidebarMenuListItem>
    );
  } else {
    /*  if(userType && userType === 'user') {
        if(page.label != 'User') {
          items.push(
            <SidebarMenuListItem
              depth={depth}
              href={page.to}
              icon={page.icon}
              key={page.label}
              label={page.badge}
              title={page.label}
            />
          );
        }
      } else {  */
    if (!page.children) {
      items.push(
        <SidebarMenuListItem
          depth={depth}
          href={page.to}
          icon={page.icon}
          key={page.label}
          label={page.badge}
          title={page.label}
        />
      );
    } if (page.children) {
      items.push(
        <SidebarMenuListItem
          depth={depth}
          icon={page.icon}
          key={page.label}
          label={page.badge}
          title={page.label}
          href=""
          children={
            <SidebarMenuList
              pages={page.children}
            />}
        />
      )
    }
    //}
  }

  return items;
};

const SidebarMenu = props => {
  const { title, pages, className, component: Component, ...rest } = props;
  var [isQbActive, setIsQbActive] = React.useState();
  const [message, setMessage] = React.useState("");
  const token = config.TOKEN
  var [userProfile, setUserProfile] = React.useState();

  function qbStatus() {
    const MOBILESURETY_SYNC_URL = config.API_URL + "/api/quickbooks/online"
    return axios.get(MOBILESURETY_SYNC_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    }).then((res) => {
      if (res.data.successCode === 0) {
        isQbActive = res.data.data
        setIsQbActive(isQbActive)
      } else {
        setMessage(
          <div className="message">
            <div className="alert alert-danger" role="alert">
              "An error occured,try again later"
            </div>
          </div>)
        setTimeout(() => { setMessage("") }, 5000)
      }
    })
  }

  React.useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    qbStatus()
    const timer = setInterval(
      () => qbStatus(),
      120 * 1000
    );
    return () => clearInterval(timer);
  }, []);

  const router = useRouter();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      
      <SidebarMenuList depth={0} pages={pages} router={router} />
      {userProfile?.userType != "ClientUser" ? <div className='qb-logo'>
        <div className='qb-status'>
          <img style={{mixBlendMode : 'multiply',paddingLeft: '7px' }} src={Qblogo} width="140px"></img>
          {isQbActive!=undefined && <span className={isQbActive==true ? "dotActive" : "dotInactive"}></span>}
        </div>
      </div> : ""}

    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

SidebarMenu.defaultProps = {
  component: 'nav'
};

export default SidebarMenu;
