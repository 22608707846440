import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Card, CardContent, FormControl, Grid, Input, InputLabel, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;


export default function AddClientUsers() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let loggedinUser = localStorage.getItem("UserName");
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "name": "",
            "address": "",
            "city": "",
            "state": "",
            "email": "",
            "phone": ""
        }
    );
    var [userName, setUserName] = React.useState("");
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    const [region, setRegion] = useState();
    const [regionError, setRegionError] = useState("");
    var [regionArray, setRegionArray] = useState([]);
    var [showRegion, setShowRegion] = useState();
    var [clientList, setClientList] = React.useState([]);
    var [regionList, setRegionList] = React.useState([]);
    var [company, setCompany] = useState("");

    useEffect(() => {
        console.log(id)
        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userName = userProfile.userName;
        setUserName(userName)
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail)
        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/clientuser/" + id
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        company = rows.customerId
                        setCompany(company)
                        getRegion(rows.customerId)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }
        getCompany()

    }, [])

    const getCompany = () => {
        const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/clientlistbasic/" + userName
        axios.get(MOBILESURETY_CLIENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    clientList = res.data.data
                    setClientList(clientList);

                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const getRegion = (company) => {
        const MOBILESURETY_REGION_URL = config.API_URL + "/api/region/basic/" + company
        axios.get(MOBILESURETY_REGION_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    regionList = res.data.data
                    setRegionList(regionList)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const customHandleChange = (e) => {
        company = e.target.value
        setCompany(company)
        getRegion(company)
    };

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Name: rows?.name, Email: rows?.email, CompanyId: rows?.customerId, Region: rows?.regionId
                }}
                validate={values => {

                    const errors = {};

                    if (!values.Name) {
                        errors.Name = 'Required!';
                    }

                    if (!company) {
                        errors.CompanyId = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        customerId: company,
                        regionId: values.Region,
                        name: values.Name,
                        email: values.Email
                    }
                    // same api for add & edit
                    const MOBILESURETY_URL = config.API_URL + "/api/clientuser/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-success" role="alert">
                                            {id == undefined ? "Client User Added Successfully!" : "Client User Updated Successfully!"}
                                        </div>
                                    </div>)
                            } else {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-danger" role="alert">
                                            "An error occured,try again later"
                                        </div>
                                    </div>)
                            }
                            setTimeout(() => {
                                navigate("/client/users/list")
                            }, 2000);
                        })
                    setSubmitting(false)
                }}

            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            {message}
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <PageTitle>Client Users</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Name" variant="standard" value={values.Name} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                    <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} name="Email" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField
                                        // fullWidth
                                        style={{ width: "99%" }}
                                        className="filterDropdown"
                                        id="status"
                                        select
                                        label="Select Company"
                                        helperText=""
                                        variant="standard"
                                        name="CompanyId"
                                        value={company || ''}
                                        onBlur={handleBlur}
                                        onChange={(e) => customHandleChange(e)}>
                                        {/* onChange={handleChange}> */}
                                        {clientList.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <span className="errMsg">{errors.CompanyId && touched.CompanyId && errors.CompanyId}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField
                                        // fullWidth
                                        style={{ width: "99%" }}
                                        className="filterDropdown"
                                        id="status"
                                        select
                                        label="Select Region"
                                        helperText=""
                                        variant="standard"
                                        name="Region"
                                        value={values.Region || ''}
                                        onBlur={handleBlur}
                                        onChange={handleChange}>
                                        {regionList.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/client/users/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )
                }
            </Formik >
        </div>
    );
}
