import React, { useEffect,Fragment, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import {

  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@mui/material';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';


export default function HeaderUserbox() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  var user;
  var [userName,setUserName] = useState("");
  var [userProfile, setUserProfile] = useState();
  const navigate = useNavigate();

  
  useEffect(() => {

    if (localStorage.getItem("userProfile") == null) {
      navigate('/login')
    }
    else {

      userProfile = localStorage.getItem("userProfile");
      userProfile = JSON.parse(userProfile);
      setUserProfile(userProfile);
      userName = userProfile.userName;
      setUserName(userName)

    }

  }, [])

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.clear();
    navigate('/login')
  }

  return (
    // <Avatar sizes="44" alt="Emma Taylor" src={avatar5} /> font-weight-bold
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <Avatar sizes="44" alt="Emma Taylor" style={{ backgroundColor: "#F59300" }}>
            {userName[0]}
          </Avatar>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="pt-2 line-height-1" style={{ color: 'black' }}>{userProfile?.firstName} {userProfile?.lastName}</div>
        </div>
        <span className="pl-1 pl-xl-3 pt-2">
          <FontAwesomeIcon icon={faAngleDown} style={{ color: 'black' }} />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        // getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <Avatar sizes="44" alt="Emma Taylor" style={{ backgroundColor: "#F59300" }}>
                {userName[0]}
              </Avatar>
            </Box>
            <div className="pl-3  pr-3">
              <div className="font-weight-bold text-center pt-2 pb-2 line-height-1">
                {userProfile?.firstName} {userProfile?.lastName}
              </div>
            </div>
            <Divider className="w-100 mt-0" />
            <ListItem >
              <Button className="mx-3" onClick={() => navigate('/user/reset-password')}>Reset Password</Button>
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-0 pb-0 text-center">
              <Tooltip arrow title="Logout">
                <Button className="text-twitter" onClick={logout}>
                  <span className="btn-wrapper--icon">
                    <AiOutlineLogout color="#F59300" fontSize="18px" />
                  </span>
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
