import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Card, CardContent, FormControl, Grid, Input, InputLabel, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;


export default function AddEditClient() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let loggedinUser = localStorage.getItem("UserName");
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "name": "",
            "address": "",
            "city": "",
            "state": "",
            "email": "",
            "phone": ""
        }
    );
    var [userName, setUserName] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    const [region, setRegion] = useState();
    const [regionError, setRegionError] = useState("");
    var [regionArray, setRegionArray] = useState([]);
    var [showRegion, setShowRegion] = useState();
    const deleteRegionMessage = () => toast("Can't delete the Region, because sites are associated with it!");

    useEffect(() => {
        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userName = userProfile.userName;
        setUserName(userName)
        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/client/" + id
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        if (rows.regionList != null) {
                            regionArray = rows?.regionList
                            setRegionArray(regionArray)
                            showRegion = true
                            setShowRegion(showRegion)
                        }
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }
    }, [])

    const addRegion = () => {
        if (!region) {
            setRegionError("Enter a region name!")
            setTimeout(() => { setRegionError("") }, 4000)
        } else {
            regionArray.push({ "name": region })
            setRegionArray(regionArray)
            setRegion('')
            setShowRegion(true)
        }
    }

    const deleteRegion = (i,e) => {
        if (e.siteCount > 0) {
            deleteRegionMessage()
        } else {
            setRegionArray((regionArray) => {
                return regionArray.filter((arrElem, index) => {
                    return index !== i;
                });
            });
        }
    }

 
    const displayRegion = () => {
        if (showRegion)
            return (
                <Grid item xs={12} sm={8} md={8} className="pt-3">
                    <Grid container spacing={4} className='pt-4'>
                        {regionArray?.map((e, index) => {
                            console.log(regionArray)
                            return (
                                <Grid item xs={12} sm={6} md={6} key={e} >
                                    <Card style={{ backgroundColor: "#F0F0F0" }} >
                                        <CardContent className="d-flex justify-content-between pb-0">
                                            <div>
                                                {id && e.id ? <p style={{ wordBreak: "break-word", cursor: "pointer" }} onClick={() => navigate("/customer/list?clientId=" + rows.id + "&regionId=" + e.id)}>{e.name}</p> 
                                                : <p>{e.name}</p>}
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteRegion(index,e)} />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }
                        )}
                    </Grid >
                </Grid>
            )
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Id: rows?.id, Name: rows?.name, Address: rows?.address, City: rows?.city,
                    State: rows?.state, Email: rows?.email, Phone: rows?.phone
                }}
                validate={values => {

                    const errors = {};

                    if (!values.Name) {
                        errors.Name = 'Required!';
                    }
                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        id: values.Id,
                        name: values.Name,
                        address: values.Address,
                        city: values.City,
                        state: values.State,
                        email: values.Email,
                        phone: values.Phone,
                        regionList: regionArray,
                        username: userName,
                    }
                    // same api for add & edit
                    const MOBILESURETY_URL = config.API_URL + "/api/client/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-success" role="alert">
                                            {id == 0 ? "Company Added Successfully!" : "Company Updated Successfully!"}
                                        </div>
                                    </div>)
                            } else {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-danger" role="alert">
                                            "An error occured,try again later"
                                        </div>
                                    </div>)
                            }
                            setTimeout(() => {
                                navigate("/client/list")
                            }, 2000);
                        })
                    setSubmitting(false)
                }}

            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            {message}
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <PageTitle>Company</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">

                                </Grid>

                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Name" variant="standard" value={values.Name} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                    <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Address" variant="standard" value={values.Address} onBlur={handleBlur} name="Address" onChange={handleChange} />
                                    <span className="errMsg">{errors.Address && touched.Address && errors.Address}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="City" variant="standard" value={values.City || ''} onBlur={handleBlur} name="City" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="State" variant="standard" value={values.State || ''} onBlur={handleBlur} name="State" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} name="Email" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Phone" variant="standard" value={values.Phone || ''} onBlur={handleBlur} name="Phone" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <FormControl style={{ width: "85%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="region">Region</InputLabel>
                                        <Input
                                            id="region"
                                            name="region"
                                            type="text"
                                            value={region}
                                            onBlur={handleBlur}
                                            onChange={e => setRegion(e.target.value)}
                                        />
                                        <span className="errMsg">{regionError}</span>
                                    </FormControl>
                                    <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                        style={{
                                            borderRadius: "50%",
                                            backgroundColor: "rgb(245, 147, 0)",
                                            color: "white",
                                            padding: "5px",
                                            fontSize: "20px",
                                            cursor: "pointer"
                                        }} onClick={() => addRegion()} />
                                </Grid>
                                {displayRegion()}
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/client/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )
                }
            </Formik >
        </div>
    );
}
