import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { Button, TextField, Autocomplete, Grid } from '@mui/material';
import Moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogContent, DialogActions } from '@mui/material'


const TableWrapper = styled.div`

.materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListGetAppIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
         .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListGetAppIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 5fr 5fr 5fr 1fr 5fr 0fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterRowUpdate {

        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        padding-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .textField {
        width : 300px
    }

    .filterAutocomplete{
    
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
            height : 35px;
            padding-top : 0px;
        }
        .MuiFilledInput-input {
            padding: none;
            font-size: 12px;
            height : 10px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }}
    .filterDropdown {
        .dialogStyle {
            height : 200px ;
            width : 1000px ;
        }
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
  
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'submitDate', numeric: false, label: 'Submitted Date' },
    { id: 'clientName', numeric: false, label: 'Client' },
    { id: 'employee', numeric: false, label: 'Employee' },
    { id: 'notes', numeric: false, label: 'Notes' },
    { id: 'payrollItem', numeric: false, label: 'Payroll Item' },
    { id: 'serviceItem', numeric: false, label: 'Service Item' },
    { id: 'count', numeric: false, label: 'Count' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function PayrollSummary() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [pdfMessage, setPdfMessage] = React.useState("")
    var [employeeId, setEmployeeId] = React.useState("");
    var [clientId, setClientId] = React.useState(-1);
    var [employeeDetails, setEmployeeDetails] = React.useState([]);
    var [clientDetails, setClientDetails] = React.useState([]);
    const [pfreferenceId, setPfreferenceId] = React.useState();
    var [batchdates, setBatchDates] = React.useState([]);
    var [cycleId, setCycleId] = React.useState(0);
    var [webforms, setWebforms] = React.useState(false);
    const token = config.TOKEN
    const navigate = useNavigate();
    var [userProfile, setUserProfile] = React.useState();
    var [userEmail, setUserEmail] = React.useState("");
    const notifyApproveWithoutSelected = () => toast("Select atleast one batch cycle!");
    var [approvalPopup, setApprovalPopup] = React.useState(false);
    var [disable, setDisable] = React.useState(false);


    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail);

        const MOBILESURETY_URL = config.API_URL + "/api/employee/basic"

        axios.get(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    employeeDetails = res.data.data
                    setEmployeeDetails(employeeDetails)
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }

            })

        const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/site/basic"
        axios.get(MOBILESURETY_CLIENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    clientDetails = res.data.data
                    setClientDetails(clientDetails)
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            })
        const MOBILESURETY_DATE_URL = config.API_URL + "/api/batchdates"
        axios.get(MOBILESURETY_DATE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    batchdates = res.data.data
                    setBatchDates(batchdates);

                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            })
        const MOBILESURETY_TIMEENTRY_URL = config.API_URL + "/api/quickbooks/search"
        var postData = {
            "cycleId": cycleId,
            "employeeEmail": employeeId,
            "customerId": clientId
        }
        axios.post(MOBILESURETY_TIMEENTRY_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(rows);
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            })
    }, [])


    const handleSearch = (event) => {

        const MOBILESURETY_TIMEENTRY_URL = config.API_URL + "/api/quickbooks/search"
        var postData = {
            "cycleId": cycleId,
            "employeeEmail": employeeId,
            "customerId": clientId
        }
        axios.post(MOBILESURETY_TIMEENTRY_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(rows);
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            })
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadcsv = () => {

        var postData = {
            "cycleId": cycleId,
            "employeeEmail": employeeId,
            "customerId": clientId,
        }
        const MOBILESURETY_URL = config.API_URL + "/api/quickbooks/export"
        const token = config.TOKEN

        axios.post(MOBILESURETY_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    const data = res.data.data;
                    const element = document.createElement("a");
                    const file = new Blob([data], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = 'export.csv';
                    document.body.appendChild(element);
                    element.click();
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            })
    }



    const handleApproval = () => {

        var postData = {
            "cycleId": cycleId,
            "employeeEmail": userEmail,

        }
        const MOBILESURETY_URL = config.API_URL + "/api/quickbooks"
        const token = config.TOKEN

        axios.post(MOBILESURETY_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                "Approved Successfully!"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }

            })
        setTimeout(() => { setApprovalPopup(false); setCycleId(0) }, 3000)
        setTimeout(() => { setMessage("") }, 5000)
    }

    const openApprovalPopup = () => {
        if (cycleId == 0) {
            notifyApproveWithoutSelected()
        } else {
            approvalPopup = true;
            setApprovalPopup(approvalPopup);
        }
    }

    const handleCycleId = (option) => {
        if (option != null)
        {
            setCycleId(option.cycleId); 
            console.log(option)
            if(option.approved == true){
            disable = true
            setDisable(disable)
            }
            else{
            disable = false
            setDisable(disable)
            }
        }
            else
                setCycleId(0)
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <TableWrapper>

                {pdfMessage}
                <div className={`${classes.root} materialTableStyle`}>

                    <div className="filterRow">

                        <Autocomplete
                            // disableClearable
                            //  disabled={disableClient}
                            options={clientDetails}
                            className="filterAutocomplete"
                            getOptionLabel={(option) => option.name}
                            onChange={(event, client) => {
                                client != null ? setClientId(client.id) : setClientId(-1)
                            }}
                            renderInput={(params) => <TextField  {...params} fullWidth label="Select Client" className="filterAutocomplete" variant="filled" />
                            }
                        />
                        <Autocomplete
                            //  disableClearable
                            options={employeeDetails}
                            className="filterAutocomplete"
                            getOptionLabel={(option) => option.name}
                            onChange={(event, employee) => {
                                employee != null ? setEmployeeId(employee.email) : setEmployeeId("")
                            }}
                            renderInput={(params) => <TextField  {...params} fullWidth label="Select Employee" className="filterAutocomplete" variant="filled" />
                            }
                        />
                        <Autocomplete
                            // disableClearable
                            options={batchdates}
                            className="filterAutocomplete"
                            getOptionLabel={(option) => option.cycleDates}
                            onChange={(event, option) => {
                                handleCycleId(option)
                                //   option != null ? (setCycleId(option.cycleId), setDisable(false)) : setCycleId(0)
                            }}
                            renderInput={(params) => <TextField
                                {...params} fullWidth label="Batch Dates" className="filterAutocomplete" variant="filled" />
                            }
                        />

                        <span>
                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={handleSearch} >
                                Search
                            </Button>
                        </span>
                        <span>
                            <div>
                                <Tooltip title="Download CSV" aria-label="Download">
                                    <IconButton className="actionCSVBtn csvDownloadIcon" aria-label="Download" onClick={() => { downloadcsv() }}>
                                        <GetAppIcon className="actionIcon" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </span>
                        <span>
                            <Dialog open={approvalPopup} closeonescape="true" maxWidth="true" >
                                <TableWrapper>
                                    <DialogContent className="dialogStyle">
                                        <div className={`${classes.root} materialTableStyle`}>
                                            Do you want to approve this batch?
                                        </div>
                                        <DialogActions>
                                            <Grid container spacing={4} >
                                                <Grid item xs={12} sm={12} md={12} className="text-center">
                                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setApprovalPopup(false)}>
                                                        No
                                                    </Button>&nbsp;&nbsp;
                                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => handleApproval()}>
                                                        Yes
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                    </DialogContent>
                                </TableWrapper>
                                {message}
                            </Dialog>
                        </span>
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>

                                {stableSort(filteredRows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((filteredRows, index) => {
                                        filteredRows.submitDate = Moment(Date.parse(filteredRows.submitDate)).format('MM/DD/YYYY')
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={filteredRows.customerId}
                                            >
                                                <TableCell>
                                                    {filteredRows.submitDate}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.clientName}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.employee}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.notes}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.payrollItem}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.serviceItem}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.count}
                                                </TableCell>
                                            </TableRow>
                                        );

                                    })}


                            </TableBody>

                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="paginationLabel"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

            </TableWrapper>
        </div>
    );
}


