import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const PageTitleWrapper = styled.div`
    position: relative;
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
`;

export default function PageTitle({color, fontSize, fontWeight, children, ...props}) {

    return (
        <PageTitleWrapper color={color} fontSize={fontSize} fontWeight={fontWeight} {...props}>{children}</PageTitleWrapper>
    )
}

PageTitle.propTypes = {
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    children: PropTypes.string
};

PageTitle.defaultProps = {
    color: '#000000',
    fontSize: '18px',
    fontWeight: '500'
};
