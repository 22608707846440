import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence} from 'framer-motion';
import { ThemeProvider } from '@mui/styles';
import MuiTheme from './theme';
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import { Loader } from './shared/components/Loader';
import Dashboard from './pages/Dashboard';
import UserList from './pages/Users/Users/UserList';
import AddUser from './pages/Users/AddUser';
import FormSubmission from './pages/FormSubmission/FormSubmission/FormSubmission';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import PayrollSummary from './pages/PayrollSummary/PayrollSummary';
import Site from './pages/Site/Site/Site';
import AddEditSite from './pages/Site/AddEditSite/AddEditSite';
import BatchDates from './pages/BatchDates/BatchDates/BatchDates';
import PayrollSync from './pages/FormSubmission/PayrollSync/PayrollSync';
import PayrollMapping from './pages/PayrollSummary/PayrollMapping/PayrollMapping';
import ErrorDetails from './pages/FormSubmission/PayrollSync/ErrorDetails';
import Clients from './pages/Clients/Clients/Clients';
import Region from './pages/Region/Region/Region';
import AddEditRegion from './pages/Region/AddEditRegion/AddEditRegion';
import AddEditClient from './pages/Clients/AddEditClients/AddEditClient';
import ClientUsers from './pages/ClientUsers/ClientUsers/ClientUsers';
import AddClientUsers from './pages/ClientUsers/AddClientUsers/AddClientUsers';


const Login = lazy(() => import('./pages/Login'));
const RoutesComponent = () => {
 
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Loader color="#ff9900" />
              </div>
            </div>
          }>
          <PresentationLayout>
            {/* login */}
          </PresentationLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/resetpwd/:pwdkey" element={<ForgotPassword />}></Route>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route element={<LeftSidebar />}>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/user/list" element={<UserList />}></Route>
              <Route path="/user/add" element={<AddUser />}></Route>
              <Route path="/user/edit/:id" element={<AddUser />}></Route>
              <Route path="/user/reset-password" element={<ResetPassword />}></Route>
              <Route path="/formsubmission/list" element={<FormSubmission />}></Route>
              <Route path="/payrollsummary/list" element={<PayrollSummary />}></Route>
              <Route path="/customer/list" element={<Site />}></Route>
              <Route path="/customer/add" element={<AddEditSite />}></Route>
              <Route path="/customer/edit/:id" element={<AddEditSite />}></Route>
              <Route path="/client/list" element={<Clients />}></Route>
              <Route path="/client/add" element={<AddEditClient />}></Route>
              <Route path="/client/edit/:id" element={<AddEditClient />}></Route>
              <Route path="/region/list" element={<Region />}></Route>
              <Route path="/region/add" element={<AddEditRegion />}></Route>
              <Route path="/region/edit/:id" element={<AddEditRegion />}></Route>
              <Route path="/batchdates/list" element={<BatchDates />}></Route>
              <Route path="/payrollsync/list" element={<PayrollSync />}></Route>
              <Route path="/payroll/mapping" element={<PayrollMapping />}></Route>
              <Route path="/qb/error/:id" element={<ErrorDetails />}></Route>
              <Route path="/client/users/list" element={<ClientUsers />}></Route>
              <Route path="/client/users/add" element={<AddClientUsers />}></Route>
              <Route path="/client/users/edit/:id" element={<AddClientUsers />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesComponent;
