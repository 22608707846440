import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from 'styled-components';
import { Button, TextField, Grid, Switch, FormControlLabel, MenuItem } from '@mui/material';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Formik } from 'formik';
import PageTitle from '../../../shared/components/PageTitle';
import { useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const TableWrapper = styled.div`

.materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListGetAppIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
         .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListGetAppIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterRowUpdate {

        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        padding-top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .textField {
        width : 300px
    }

    .filterAutocomplete{
    
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
            height : 35px;
            padding-top : 0px;
        }
        .MuiFilledInput-input {
            padding: none;
            font-size: 12px;
            height : 10px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }}
    .filterDropdown {
       
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
    .dialogStyle {
        height : 450px;
        width : 400px;
    }
    deleteDialogStyle {
        height : 200px;
        width : 200px;
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'submitDate', numeric: false, label: 'Active In ProntoForms' },
    { id: 'clientName', numeric: false, label: 'Payroll Item' },
    { id: 'employee', numeric: false, label: 'Service Item' },
    { id: 'notes', numeric: false, label: 'Form Type' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function PayrollMapping() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [pdfMessage, setPdfMessage] = React.useState("")
    var [employeeId, setEmployeeId] = React.useState("");
    var [clientId, setClientId] = React.useState(-1);
    var [formTypes, setFormTypes] = React.useState([]);
    var [payrollItems, setPayrollItems] = React.useState([]);
    var [serviceItems, setServiceItems] = React.useState([]);
    var [cycleId, setCycleId] = React.useState(0);
    const token = config.TOKEN
    const navigate = useNavigate();
    var [userProfile, setUserProfile] = React.useState();
    var [userEmail, setUserEmail] = React.useState("");
    var [openPopup, setOpenPopup] = React.useState(false);
    var [disable, setDisable] = React.useState(false);
    var [search, setSearch] = React.useState("");
    var [id, setId] = React.useState(0);
    var [deletePopup, setDeletePopup] = React.useState(false);
    const [deleteMessage, setDeleteMessage] = React.useState(" ");
    var [activeInPf, setActiveInPf] = React.useState(true);
    var [data, setData] = React.useState([]);


    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail);

        getPayrollMap();

        const MOBILESURETY_URL_STATIC_DATA = config.API_URL + "/api/quickbooks/staticdata"

        axios.get(MOBILESURETY_URL_STATIC_DATA, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    formTypes = res.data.data.formTypes
                    setFormTypes(formTypes)
                    serviceItems = res.data.data.serviceItems
                    setServiceItems(serviceItems)
                    payrollItems = res.data.data.payrollItems
                    setPayrollItems(payrollItems)

                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }

            })


    }, [])

    const getPayrollMap = () => {
        const MOBILESURETY_URL = config.API_URL + "/api/payrollmap"

        axios.get(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(rows);

                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }

            })

    }


    const handleSearch = (event) => {

        search = event.target.value;
        setSearch(search);
        filteredRows = rows.filter(a => (a.serviceItem ? a.serviceItem.toLowerCase().includes(search.toLowerCase()) : a.serviceItem) || (a.payrollItem != null ? a.payrollItem.toLowerCase().includes(search.toLowerCase()) : a.payrollItem))
        setFilteredRows(filteredRows)
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const open = () => {
        openPopup = true;
        setOpenPopup(openPopup);
    }

    const updatePayrollMap = (id) => {
        openPopup = true;
        setOpenPopup(openPopup);
        id = id
        setId(id)
        const MOBILESURETY_URL = config.API_URL + "/api/payrollmap/" + id

        axios.get(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    data = res.data.data
                    setData(data[0])
                    activeInPf = data[0].activeInPf
                    setActiveInPf(activeInPf)
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }

            })
    }

    const openDeletePopup = (id) => {
        id = id
        setId(id)
        deletePopup = true;
        setDeletePopup(deletePopup);
    }

    const deleteCustomer = () => {
        const MOBILESURETY_URL = config.API_URL + "/api/payrollmap/delete/" + id
        const token = config.TOKEN
        axios.delete(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                {
                    if (res.data.successCode === 0) {
                        setDeleteMessage(
                            <div className="message">
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    Payroll Map deleted successfully!
                                </div>
                            </div>)
                        getPayrollMap()
                    } else {
                        setDeleteMessage(
                            <div className="message">
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    "An error occured, contact support".
                                </div>
                            </div>)
                    }
                    setTimeout(() => { setDeletePopup(false) }, 3000)
                    setTimeout(() => { setDeleteMessage("") }, 5000)
                }
            })
    }


    return (
        <div>
            <ToastContainer theme="dark" />
            <TableWrapper>

                {pdfMessage}
                <div className={`${classes.root} materialTableStyle`}>
                    <div className="filterRow">
                        <div>
                            <FilterInput placeholder="Search" icon={<FilterListIcon className="filterIconSize" />} value={search} onChange={handleSearch} />
                        </div>
                        <div></div>
                        <div></div>
                        <div className="m-2">
                            <Button variant="contained" color="primary" className="noShadow w-100 addBtn "
                                onClick={() => { open(); id = 0; setId(id) }}
                            >Add </Button>
                            <Dialog open={openPopup} closeonescape="true" maxWidth="true" >
                                <TableWrapper>
                                    <DialogContent className="dialogStyle">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={id == 0 ?
                                                {
                                                    PayrollItem: "", ServiceItem: "",
                                                    FormType: "", ActiveInPf: false
                                                } : {
                                                    PayrollItem: data?.payrollItem, ServiceItem: data?.serviceItem,
                                                    FormType: data?.formType, ActiveInPf: data?.activeInPf, Id : data?.id
                                                }}

                                            validate={values => {
                                                const errors = {};
                                                if (!values.PayrollItem) {
                                                    errors.PayrollItem = 'Required!';
                                                }
                                                if (!values.ServiceItem) {
                                                    errors.ServiceItem = 'Required!';
                                                }

                                                if (!values.FormType) {
                                                    errors.FormType = 'Required!';
                                                }

                                                return errors;
                                            }}

                                            const handleMouseDownPwd={(event) => {
                                                event.preventDefault();
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                const postData = {
                                                    id: values.Id,
                                                    payrollItem: values.PayrollItem,
                                                    serviceItem: values.ServiceItem,
                                                    formType: values.FormType,
                                                    activeInPf: activeInPf,
                                                    userEmail: userEmail
                                                }
                                                // same api for add & edit
                                                const MOBILESURETY_URL = config.API_URL + "/api/payrollmap/add"
                                                axios.post(MOBILESURETY_URL, postData, {
                                                    headers: {
                                                        'Authorization': `Basic ${token}`
                                                    }
                                                })
                                                    .then((res) => {

                                                        if (res.data.successCode === 0) {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-success" role="alert">
                                                                        {id == 0 ? "Mapping Added Successfully!" : "Mapping Updated Successfully!"}
                                                                    </div>
                                                                </div>)
                                                        } else {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-danger" role="alert">
                                                                        "An error occured,try again later"
                                                                    </div>
                                                                </div>)
                                                        }
                                                        getPayrollMap()

                                                        setTimeout(() => { setMessage(""); setOpenPopup(false) }, 3000)

                                                    })
                                                setSubmitting(false)
                                            }}
                                        >

                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting,

                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    {message}
                                                    <Grid container spacing={4} className='d-flex justify-content-center'>
                                                        <Grid item xs={12} sm={8} md={8} className="pt-5">
                                                            <PageTitle>Payroll Mapping </PageTitle>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} className="pt-2">
                                                            <TextField
                                                                // fullWidth
                                                                style={{ width: "99%" }}
                                                                className="filterDropdown"
                                                                id="status"
                                                                select
                                                                label="Select Payroll Item"
                                                                helperText=""
                                                                variant="standard"
                                                                name="PayrollItem"
                                                                value={values.PayrollItem || ''}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}>
                                                                {payrollItems.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                            <span className="errMsg">{errors.PayrollItem && touched.PayrollItem && errors.PayrollItem}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} className="pt-3">
                                                            <TextField
                                                                // fullWidth
                                                                style={{ width: "99%" }}
                                                                className="filterDropdown"
                                                                id="status"
                                                                select
                                                                label="Select Service Item"
                                                                helperText=""
                                                                variant="standard"
                                                                name="ServiceItem"
                                                                value={values.ServiceItem || ''}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}>
                                                                {serviceItems.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                            <span className="errMsg">{errors.ServiceItem && touched.ServiceItem && errors.ServiceItem}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} className="pt-3">
                                                            <TextField
                                                                // fullWidth
                                                                style={{ width: "99%" }}
                                                                className="filterDropdown"
                                                                id="status"
                                                                select
                                                                label="Select Form Type"
                                                                helperText=""
                                                                variant="standard"
                                                                name="FormType"
                                                                value={values.FormType || ''}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}>
                                                                {formTypes.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                            <span className="errMsg">{errors.FormType && touched.FormType && errors.FormType}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} className="py-4 pb-0">
                                                            <FormControlLabel control={<Switch
                                                                checked={activeInPf}
                                                                name="ActiveInPf"
                                                                color="warning"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                onChange={e => setActiveInPf(e.target.checked)}
                                                            //   value={filteredRows.activeInPf}
                                                            //onClick={(event) => deleteUser(event, filteredRows)}
                                                            />}
                                                                label="Active In ProntoForms" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} md={8} className="text-right">
                                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setOpenPopup(false)}>
                                                                Cancel
                                                            </Button>&nbsp;&nbsp;
                                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )}
                                        </Formik>
                                    </DialogContent>
                                </TableWrapper>
                            </Dialog>
                        </div>
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>

                                {stableSort(filteredRows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((filteredRows, index) => {
                                        filteredRows.submitDate = Moment(Date.parse(filteredRows.submitDate)).format('MM/DD/YYYY')
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={filteredRows.id}
                                            >
                                                <TableCell>
                                                    {filteredRows.activeInPf ? "Yes" : "No"}
                                                    {/* <Switch
                                                        checked={filteredRows.activeInPf}
                                                        name="status"
                                                        color="warning"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                     //   value={filteredRows.activeInPf}
                                                    //  onClick={(event) => deleteUser(event, filteredRows)}
                                                    /> */}
                                                </TableCell>
                                                <TableCell>
                                                {filteredRows.payrollItem}
                                                </TableCell>
                                                <TableCell>
                                                {filteredRows.serviceItem}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.formType}
                                                    <div className="actionListEditIconPosition">
                                                        <Tooltip title="Edit" aria-label="edit">
                                                            <IconButton className="actionBtn" aria-label="edit" onClick={() => { updatePayrollMap(filteredRows.id) }}>
                                                                <EditIcon className="actionIcon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="actionListDeleteIconPosition">
                                                        <Tooltip title="delete" aria-label="delete">
                                                            <IconButton className="actionBtn" aria-label="delete" onClick={() => { openDeletePopup(filteredRows.id) }}>
                                                                <DeleteIcon className="actionIcon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                                                            <TableWrapper>
                                                                <DialogContent className="deleteDialogStyle">
                                                                    <div className={`${classes.root} materialTableStyle`}>
                                                                        Do you want to delete the record?
                                                                    </div>
                                                                    <DialogActions>
                                                                        <Grid container spacing={4} className="mt-1">
                                                                            <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                                                                    No
                                                                                </Button>&nbsp;&nbsp;
                                                                                <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteCustomer()}>
                                                                                    Yes
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </DialogActions>
                                                                </DialogContent>
                                                            </TableWrapper>
                                                            {deleteMessage}
                                                        </Dialog>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="paginationLabel"
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

            </TableWrapper>
        </div>
    );
}


