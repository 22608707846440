import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const LoaderWrapper = styled.div`
.loading {
    height: 0;
    width: 0;
    padding: 15px;
    border: 4px solid #f7f7f7;
    border-right-color: ${props => props.color};
    border-radius: 22px;
    -webkit-animation: rotate 1s infinite linear;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export function Loader({color}) {
    return (
        <LoaderWrapper color={color}>
          <div className="loading"></div>  
        </LoaderWrapper>
    )
}

Loader.propTypes = {
    color: PropTypes.string
};

Loader.defaultProps = {
    color: '#ff9900'
};
