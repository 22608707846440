import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RoutesComponent from './RoutesComponent';
//import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@mui/material/CssBaseline';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../src/theme/base.scss';
import 'bootstrap5/src/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import configureStore from './config/configureStore';
 
const store = configureStore();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <CssBaseline />
        {/*  <ScrollToTop>  */}
            <PerfectScrollbar>
              <RoutesComponent />
            </PerfectScrollbar>
         {/*  </ScrollToTop>  */}
        </BrowserRouter>
        </Provider>
    );
  }
}

export default App;
