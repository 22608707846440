import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Card, CardContent, FormControl, Grid, Input, InputLabel, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;


export default function AddEditRegion() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let loggedinUser = localStorage.getItem("UserName");
    var [clientList, setClientList] = useState([]);
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "name": "",
            "contactName": "",
            "contactPhone": "",
            "clientId": ""
        }
    );

    useEffect(() => {
        if (id) {
            const MOBILESURETY_REGION_URL = config.API_URL + "/api/region/" + id
            axios.get(MOBILESURETY_REGION_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data[0]
                        setRows(rows);
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }

        getClients()
    }, [])

    const getClients = () => {
        const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/clientlistbasic/" + loggedinUser
        axios.get(MOBILESURETY_CLIENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    clientList = res.data.data
                    setClientList(clientList);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }


    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                  Name: rows?.name
                //  Client: rows?.clientId, ContactName: rows?.contactName, ContactPhone: rows?.contactPhone
            }}
            validate={values => {

                const errors = {};
                // if (!values.Client) {
                //     errors.Client = 'Required!';
                // }
                if (!values.Name) {
                    errors.Name = 'Required!';
                }
                return errors;
            }}

            const handleMouseDownPwd={(event) => {
                event.preventDefault();
            }}
            onSubmit={(values, { setSubmitting }) => {
                const postData = {
                    id: id ? id : 0,
                    // clientId: values.Client,
                    name: values.Name,
                    // contactName: values.ContactName,
                    // contactPhone: values.ContactPhone,
                    userName: loggedinUser
                }

                const MOBILESURETY_URL = config.API_URL + "/api/region/add"
                axios.post(MOBILESURETY_URL, postData, {
                    headers: {
                        'Authorization': `Basic ${token}`
                    }
                })
                    .then((res) => {

                        if (res.data.successCode === 0) {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-success" role="alert">
                                        {id ? "Region Updated Successfully!" : "Region Added Successfully!"}
                                    </div>
                                </div>)
                        } else {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-danger" role="alert">
                                        "An error occured,try again later"
                                    </div>
                                </div>)
                        }
                        setTimeout(() => {
                            navigate("/region/list")
                        }, 2000);
                    })
                setSubmitting(false)
            }}

        >

            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <UserWrapper>
                        {message}
                        <Grid container spacing={4} className='d-flex justify-content-center'>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <PageTitle>Region</PageTitle>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">

                            </Grid>

                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="Name" variant="standard" value={values.Name} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                            </Grid>
                            {/* <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField
                                    // fullWidth
                                    style={{ width: "99%" }}
                                    className="filterDropdown"
                                    id="status"
                                    select
                                    label="Select Company"
                                    helperText=""
                                    variant="standard"
                                    name="Client"
                                    value={values.Client || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}>
                                    {clientList.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span className="errMsg">{errors.Client && touched.Client && errors.Client}</span>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="Contact Name" variant="standard" value={values.ContactName} onBlur={handleBlur} name="ContactName" onChange={handleChange} />
                                <span className="errMsg">{errors.ContactName && touched.ContactName && errors.ContactName}</span>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} className="pt-3">
                                <TextField label="Contact Phone" variant="standard" value={values.ContactPhone || ''} onBlur={handleBlur} name="ContactPhone" onChange={handleChange} />
                            </Grid> */}
                            <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/region/list")}>
                                    Cancel
                                </Button>&nbsp;&nbsp;
                                <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </UserWrapper>
                </form>
            )
            }
        </Formik >
    );
}
