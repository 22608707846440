import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from 'styled-components';
import '@react-pdf-viewer/core/lib/styles/index.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";


const TableWrapper = styled.div`

.materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListGetAppIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                    .actionApproveIconPosition{
                        display: block;
                    }
                    .actionRejectIconPosition{
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
         .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListGetAppIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionApproveIconPosition{
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionRejectIconPosition{
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
        .approveActionIcon {
            font-size: 17px;
            font-weight : 1500px ;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 4fr 4fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .textField {
        width : 300px
    }

    .filterAutocomplete{
    
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 35px;
        padding-top : 0px;
    }
    .MuiFilledInput-input {
        padding: none;
        font-size: 12px;
        height : 10px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }}
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'record', numeric: false, label: 'Record' },
    { id: 'statusCode', numeric: false, label: 'Status Code' },
    { id: 'message', numeric: false, label: 'Message' }
];




const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function ErrorDetails() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [pdfMessage, setPdfMessage] = React.useState("")
    var [userProfile, setUserProfile] = React.useState();
    var [userEmail, setUserEmail] = React.useState("");
    var [userType, setUserType] = React.useState(false);
    const { id } = useParams();


    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >

                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }


    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const formData = [{ formId: "147117107", formName: "OSHA Compliance" },
    { formId: "146928038", formName: "Industrial Compliance" }, { formId: "1422306014", formName: "Training" }, { formId: "1422306010", formName: "Workorder" }]

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile?.userEmail;
        setUserEmail(userEmail);
        userType = userProfile?.userType;
        setUserType(userType);


        const MOBILESURETY_URL = config.API_URL + "/api/quickbooks/syncerrors/" + id
        const token = config.TOKEN
        axios.get(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows)
                    filteredRows = rows
                    setFilteredRows(filteredRows)
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }

            })
    }, [])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <div>
            <ToastContainer theme="dark" />
            <TableWrapper>
                {message}
                {pdfMessage}
                <div className={`${classes.root} materialTableStyle`}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {stableSort(filteredRows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((filteredRows, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={filteredRows.id}
                                            >
                                                <TableCell>
                                                    <div className='d-flex'>
                                                        {filteredRows.record}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.statusCode}
                                                </TableCell>
                                                <TableCell>
                                                    {filteredRows.message}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className="paginationLabel"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </TableWrapper>
        </div>

    );
}



