import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import PageTitle from '../../../shared/components/PageTitle';
import { Formik } from 'formik';
import Moment from 'moment';


const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
       
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'startDate', numeric: false, label: 'Start Date' },
    { id: 'endDate', numeric: false, label: 'End Date' },
    { id: 'publishDate', numeric: false, label: 'Publish Date' },
    { id: 'successCount', numeric: false, label: 'Success Count' },
    { id: 'failureCount', numeric: false, label: 'Failure Count' },
    { id: 'failure', numeric: false, label: 'Failure Details' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function BatchDates() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState("");
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const token = config.TOKEN
    const [addBatchPopup, setAddBatchPopup] = React.useState(false);
    const [batchMessage, setBatchMessage] = React.useState(" ");
    var [startDateRange, setStartDateRange] = React.useState("");
    var [endDateRange, setEndDateRange] = React.useState("");
    var [startDate, setStartDate] = React.useState("");
    var [endDate, setEndDate] = React.useState("");
    var [approvalPopup, setApprovalPopup] = React.useState(false);
    var [cycleId, setCycleId] = React.useState(0);
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    const navigate = useNavigate();

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail);

        const MOBILESURETY_DATE_URL = config.API_URL + "/api/daterange"
        axios.get(MOBILESURETY_DATE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    startDateRange = res.data.data[0]
                    setStartDateRange(startDateRange)
                    endDateRange = res.data.data[1]
                    setEndDateRange(endDateRange)
                    var firstDateArray = res.data.data[0].split("T")
                    startDate = firstDateArray[0]
                    setStartDate(startDate)
                    var lastDateArray = res.data.data[1].split("T")
                    endDate = lastDateArray[0]
                    setEndDate(endDate)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
        getBatchDates();
    }, [])

    const getBatchDates = () => {
        const MOBILESURETY_SYNC_URL = config.API_URL + "/api/quickbooks/syncbatch"
        axios.get(MOBILESURETY_SYNC_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(filteredRows)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handelApprovalPopup = (filteredRows) => {
        cycleId = filteredRows.id
        setCycleId(cycleId);
        approvalPopup = true;
        setApprovalPopup(approvalPopup)
    }

    const handleApproval = (filteredRows) => {
        if (filteredRows.approved == 1) {
            return (
                <div className='mx-3'>
                    Approved
                </div>
            )
        } else {
            return (
                <div>
                    <Button variant="contained" color="primary" className="noShadow  addBtn "
                        onClick={() => handelApprovalPopup(filteredRows)}
                    >
                        QB Sync
                    </Button>
                    <Dialog open={approvalPopup} closeonescape="true" maxWidth="true" >
                        <TableWrapper>
                            <DialogContent className="dialogStyle">
                                <div className={`${classes.root} materialTableStyle`}>
                                    Do you want to approve this batch?
                                </div>
                                <DialogActions>
                                    <Grid container spacing={4} >
                                        <Grid item xs={12} sm={12} md={12} className="text-center">
                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setApprovalPopup(false)}>
                                                No
                                            </Button>&nbsp;&nbsp;
                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => handleApprovalStatus()}>
                                                Yes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </DialogContent>
                        </TableWrapper>
                        {message}
                    </Dialog>
                </div>
            )
        }
    }

    const handleApprovalStatus = () => {

        var postData = {
            "cycleId": cycleId,
            "employeeEmail": userEmail,

        }
        const MOBILESURETY_URL = config.API_URL + "/api/quickbooks"
        const token = config.TOKEN

        axios.post(MOBILESURETY_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                Approved Successfully!
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                An error occured,try again later
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
                setTimeout(() => { setApprovalPopup(false); setCycleId(0) }, 3000)
                setTimeout(() => { setMessage("") }, 3000)
                getBatchDates();
            })

    }

    return (

        <TableWrapper>
            {batchMessage}
            <div className={`${classes.root} materialTableStyle`}>
                {/* <div className="filterRow">
                    <div>
                        {/* <FilterInput placeholder="Search" icon={<FilterListIcon className="filterIconSize" />} value={search} onChange={handleSearch} /> 
                    </div>
                    <div></div>
                    <div></div>
                    <div className="m-2">
                        <Button variant="contained" color="primary" className="noShadow w-100 addBtn "
                            onClick={() => setAddBatchPopup(true)}
                        >
                            Add
                        </Button>
                        <Dialog open={addBatchPopup} closeonescape="true" maxWidth="true" >
                            <TableWrapper>
                                <DialogContent className="dialogStyle">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            StartDate: startDate, EndDate: endDate
                                        }}
                                        validate={values => {

                                            const errors = {};
                                            if (!values.StartDate) {
                                                errors.StartDate = 'Required!';
                                            }
                                            if (!values.EndDate) {
                                                errors.EndDate = 'Required!';
                                            }
                                            return errors;
                                        }}

                                        const handleMouseDownPwd={(event) => {
                                            event.preventDefault();
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            const MOBILESURETY_URL = config.API_URL + "/api/quickbooks/syncbatch"
                                            const token = config.TOKEN
                                            const postData = {
                                                startDate: values.StartDate,
                                                endDate: values.EndDate
                                            }
                                            axios.post(MOBILESURETY_URL, postData, {
                                                headers: {
                                                    'Authorization': `Basic ${token}`
                                                }
                                            })
                                                .then((res) => {
                                                    {
                                                        if (res.data.successCode === 0) {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                                        Batch added successfully!
                                                                    </div>
                                                                </div>)
                                                        } else {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                                        "An error occured, contact support".
                                                                    </div>
                                                                </div>)
                                                        }
                                                        setTimeout(() => { setAddBatchPopup(false) }, 3000)
                                                        setTimeout(() => { setMessage("") }, 5000)
                                                        getBatchDates();
                                                    }
                                                })
                                            setSubmitting(false)
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,

                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                {message}
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <PageTitle>Add QuickBooks Sync Dates</PageTitle>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} className="text-right">

                                                    </Grid>
                                                </Grid>
                                                <br></br>

                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <TextField label="Start Date" type="date" variant="standard" value={values.StartDate || ''} onBlur={handleBlur} InputLabelProps={{
                                                            shrink: true,
                                                        }} name="StartDate" onChange={handleChange} />
                                                        <div className="errMsg">{errors.StartDate && touched.StartDate && errors.StartDate}</div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <TextField label="End Date" type="date" variant="standard" value={values.EndDate || ''} onBlur={handleBlur} name="EndDate" onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }} />
                                                        <div className="errMsg">{errors.EndDate && touched.EndDate && errors.EndDate}</div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={4} >
                                                    <Grid item xs={12} sm={12} md={12} className="text-right mt-4">
                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setAddBatchPopup(false)}>
                                                            Cancel
                                                        </Button>&nbsp;&nbsp;
                                                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                            Submit
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                        }
                                    </Formik >
                                </DialogContent>
                            </TableWrapper>
                        </Dialog>
                    </div>
                </div> */}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>

                            {stableSort(filteredRows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((filteredRows, index) => {
                                    filteredRows.startDate = filteredRows.startDate && Moment(Date.parse(filteredRows.startDate)).format('MM/DD/YYYY')
                                    filteredRows.endDate = filteredRows.endDate && Moment(Date.parse(filteredRows.endDate)).format('MM/DD/YYYY')
                                    filteredRows.syncDate = filteredRows.syncDate && Moment(Date.parse(filteredRows.syncDate)).format('MM/DD/YYYY hh:mm A')
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={filteredRows.id}
                                        >
                                            <TableCell>
                                                {filteredRows.startDate}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.endDate}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.syncDate}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.successCount}
                                            </TableCell>
                                            <TableCell align="left" onClick={()=>navigate("/qb/error/"+filteredRows.id)}>
                                                {filteredRows.failCount}

                                            </TableCell>
                                            {/* <TableCell align="left">
                                                {handleApproval(filteredRows)}
                                            </TableCell> */}
                                            <TableCell align="left">
                                                {filteredRows.failCount > 0 &&
                                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn"  onClick={()=>navigate("/qb/error/"+filteredRows.id)}>
                                                Details
                                            </Button>
                                                }
                                            </TableCell>
                                           
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="paginationLabel"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </TableWrapper>
    );
}


