import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Card, CardContent, FormControl, Grid, Input, InputLabel, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;


export default function EditCustomer() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let loggedinUser = localStorage.getItem("UserName");
    var [distributionEmailArray, setDistributionEmailArray] = useState([]);
    var [showDistributionEmail, setShowDistributionEmail] = useState();
    const [distributionEmail, setDistributionEmail] = useState("");
    const [distributionEmailError, setDistributionEmailError] = useState("");
    const { id } = useParams();
    const token = config.TOKEN;
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "client": "",
            "company": "",
            "qbName": "",
            "city": "",
            "address": "",
            "email": "",
            "phone": '',
            "isActive": false,
            "distributionEmail": [],
            "customerId": null,
            "regionId": null
        }
    );
    const [isPaused, setIsPaused] = useState(false)
    var [clientList, setClientList] = useState([]);
    var [company, setCompany] = useState("");
    var [regionList, setRegionList] = useState([]);
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();

    useEffect(() => {

        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail)

        if (id) {
            const MOBILESURETY_USER_URL = config.API_URL + "/api/site/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data[0]
                        setRows(rows);
                        if (rows.customerId != null) {
                            company = rows?.customerId
                            setCompany(company)
                            getRegion(company)
                        }
                        setIsPaused(rows.isPaused)
                        if (Array.isArray(rows?.distributionEmail)) {
                            distributionEmailArray = rows?.distributionEmail
                            setDistributionEmailArray(distributionEmailArray)
                        }
                        showDistributionEmail = true
                        setShowDistributionEmail(showDistributionEmail)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }

        getCompany()

    }, [])

    const getCompany = () => {
        const MOBILESURETY_CLIENT_URL = config.API_URL + "/api/clientlistbasic/" + userEmail
        axios.get(MOBILESURETY_CLIENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    clientList = res.data.data
                    setClientList(clientList);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const getRegion = (company) => {
        const MOBILESURETY_REGION_URL = config.API_URL + "/api/region/basic/" + company
        axios.get(MOBILESURETY_REGION_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    regionList = res.data.data
                    setRegionList(regionList)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const addDistributionEmail = () => {
        if (!distributionEmail) {
            setDistributionEmailError("Enter Email Id!")
            setTimeout(() => { setDistributionEmailError("") }, 4000)
        } else {
            distributionEmailArray.push(distributionEmail)
            setDistributionEmailArray(distributionEmailArray)
            setDistributionEmail('')
            setShowDistributionEmail(true)
        }
    }

    const deleteDistributionEmail = (i) => {
        setDistributionEmailArray((distributionEmailArray) => {
            return distributionEmailArray.filter((arrElem, index) => {
                return index !== i;
            });
        });
    }

    const displayDistributionEmail = () => {
        if (showDistributionEmail)
            return (
                <Grid container spacing={4} className="pt-4">
                    {distributionEmailArray?.map((e, index) => {
                        return (
                            <Grid item xs={12} sm={4} md={4} key={e} className="px-5">
                                <Card style={{ backgroundColor: "#F0F0F0" }} >
                                    <CardContent className="d-flex justify-content-between pb-0">
                                        <div>
                                            <p style={{ wordBreak: "break-word" }}>{e}</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteDistributionEmail(index)} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                    )}
                </Grid >
            )
    }

    const customHandleChange = (e) => {
        company = e.target.value
        setCompany(company)
        getRegion(company)
    };


    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                Client: rows?.client, Company: rows?.company, CustomerType: rows?.customerType, QbName: rows?.qbName, Address: rows?.address, City: rows?.city, State: rows?.state, Phone: rows?.phone, IsActive: false, Email: rows?.email,
                AdminContact: rows?.adminContact, CompanyId: rows?.customerId, Region: rows?.regionId
            }}
            validate={values => {

                const errors = {};
                if (!values.Client) {
                    errors.Client = 'Required!';
                }
                if (!company) {
                    errors.CompanyId = 'Required!';
                }
                if (!values.QbName && userProfile?.userType != "ClientUser") {
                    errors.QbName = 'Required!';
                }
                if (!values.Region) {
                    errors.Region = 'Required!';
                }

                return errors;
            }}


            const handleMouseDownPwd={(event) => {
                event.preventDefault();
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                const postData = {
                    id: id ? id : 0,
                    client: values.Client,
                    // company: values.Company,
                    qbName: values.QbName,
                    customerType: values.CustomerType,
                    city: values.City,
                    address: values.Address,
                    state: values.State,
                    phone: values.Phone,
                    adminContact: values.AdminContact,
                    isActive: values.IsActive,
                    email: values.Email,
                    distributionEmail: distributionEmailArray,
                    createdBy: loggedinUser,
                    isPaused: isPaused,
                    customerId: company,
                    regionId: values.Region
                }

                const MOBILESURETY_URL = config.API_URL + "/api/site/add"
                axios.post(MOBILESURETY_URL, postData, {
                    headers: {
                        'Authorization': `Basic ${token}`
                    }
                })
                    .then((res) => {

                        if (res.data.successCode === 0) {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-success" role="alert">
                                        {id ? "Customer Info Updated Successfully!" : "Customer Added Successfully!"}
                                    </div>
                                </div>)
                        } else {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-danger" role="alert">
                                        "An error occured,try again later"
                                    </div>
                                </div>)
                        }
                        navigate("/customer/list")
                    })

                    setSubmitting(false)
            }}
        >

            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <UserWrapper>
                        {message}
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6}>
                                <PageTitle>Site</PageTitle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className="text-right">

                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6} className="pt-3">
                                <TextField
                                    // fullWidth
                                    style={{ width: "99%" }}
                                    className="filterDropdown"
                                    id="status"
                                    select
                                    label="Select Company"
                                    helperText=""
                                    variant="standard"
                                    name="CompanyId"
                                    value={company || ''}
                                    onBlur={handleBlur}
                                    onChange={(e) => customHandleChange(e)}>
                                    {/* onChange={handleChange}> */}
                                    {clientList.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span className="errMsg">{errors.CompanyId && touched.CompanyId && errors.CompanyId}</span>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} className="pt-3">
                                <TextField
                                    // fullWidth
                                    style={{ width: "99%" }}
                                    className="filterDropdown"
                                    id="status"
                                    select
                                    label="Select Region"
                                    helperText=""
                                    variant="standard"
                                    name="Region"
                                    value={values.Region || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}>
                                    {regionList.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span className="errMsg">{errors.Region && touched.Region && errors.Region}</span>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Site Name" variant="standard" value={values.Client} onBlur={handleBlur} name="Client" onChange={handleChange} />
                                <span className="errMsg">{errors.Client && touched.Client && errors.Client}</span>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Company" variant="standard" value={values.Company} onBlur={handleBlur} name="Company" onChange={handleChange} />
                                <span className="errMsg">{errors.Company && touched.Company && errors.Company}</span>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Client Type" variant="standard" value={values.CustomerType || ''} onBlur={handleBlur} name="CustomerType" onChange={handleChange} />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Address" variant="standard" value={values.Address} onBlur={handleBlur} name="Address" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="City" variant="standard" value={values.City} onBlur={handleBlur} name="City" onChange={handleChange} />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="State" variant="standard" value={values.State || ''} onBlur={handleBlur} name="State" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Email" variant="standard" value={values.Email} onBlur={handleBlur} name="Email" onChange={handleChange} />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Phone" variant="standard" value={values.Phone} onBlur={handleBlur} name="Phone" onChange={handleChange} />
                                <span className="errMsg">{errors.Phone && touched.Phone && errors.Phone}</span>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label="Admin Contact" variant="standard" value={values.AdminContact || ''} onBlur={handleBlur} name="AdminContact" onChange={handleChange} />
                                <span className="errMsg">{errors.AdminContact && touched.AdminContact && errors.AdminContact}</span>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl style={{ width: "85%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                    <InputLabel htmlFor="distributionEmail">Distribution Email</InputLabel>
                                    <Input
                                        id="distributionEmail"
                                        name="DistributionEmail"
                                        type="text"
                                        value={distributionEmail}
                                        onBlur={handleBlur}
                                        onChange={e => setDistributionEmail(e.target.value)}
                                    />
                                    <span className="errMsg">{distributionEmailError}</span>
                                </FormControl>
                                <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                    style={{
                                        borderRadius: "50%",
                                        backgroundColor: "rgb(245, 147, 0)",
                                        color: "white",
                                        padding: "5px",
                                        fontSize: "20px",
                                        cursor: "pointer"
                                    }} onClick={() => addDistributionEmail()} />
                            </Grid>
                            {userProfile?.userType != "ClientUser" && <Grid item xs={12} sm={6} md={6}>
                                <TextField label="QuickBooks Name" variant="standard" value={values.QbName} onBlur={handleBlur} name="QbName" onChange={handleChange} />
                                <span className="errMsg">{errors.QbName && touched.QbName && errors.QbName}</span>
                            </Grid>}
                        </Grid>

                        <br></br>
                        {userProfile?.userType != "ClientUser" && <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6} className='mt-3'>
                                <FormControlLabel control={<Switch
                                    checked={!isPaused}
                                    name="ActiveInPf"
                                    color="warning"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={e => setIsPaused(!e.target.checked)}
                                />}
                                    label="Active" />
                            </Grid>
                        </Grid>}
                        {displayDistributionEmail()}
                        <Grid container spacing={4} >
                            <Grid item xs={12} sm={12} md={12} className="text-right mt-4">
                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/customer/list")}>
                                    Cancel
                                </Button>&nbsp;&nbsp;
                                <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </UserWrapper>
                </form>
            )
            }
        </Formik >
    );
}
